<template>
  <div ref="pdfViewerContainer" style="height: calc(100vh - 64px)" />
</template>

<script>
import PSPDFKit from 'pspdfkit'
import { fetchDocument } from '@/api'
import partition from 'lodash/partition'
import downloadFile from '@/download'

async function downloadPDF(pspdfKitInstance, annotateOn) {
  const pdf = await pspdfKitInstance.exportPDF()
  const blob = new Blob([pdf], { type: 'application/pdf' })
  const filename = annotateOn.file.replace(/^.*[\\/]/, '')
  const downloadFileName = [annotateOn?.agenda_item?.number, filename].filter(Boolean).join('-')
  const objectUrl = window.URL.createObjectURL(blob)
  downloadFile(objectUrl, downloadFileName)
  window.URL.revokeObjectURL(objectUrl)
}

function createCustomDownloadToolbarItem(pspdfKitInstance, annotateOn) {
  return {
    type: 'custom',
    title: 'Export',
    id: 'custom-pdf-export',
    icon: '/export-pdf.svg',
    onPress: () => downloadPDF(pspdfKitInstance, annotateOn),
  }
}

function setCustomToolbar(pspdfKitInstance, annotateOn) {
  return pspdfKitInstance.setToolbarItems((items) =>
    items.map((item) =>
      item.type === 'export-pdf' ? createCustomDownloadToolbarItem(pspdfKitInstance, annotateOn) : item,
    ),
  )
}

export default {
  name: 'PSPDFKITViewer',
  props: {
    annotateOn: {
      type: Object,
      default: () => {},
    },
  },
  viewer: null,
  watch: {
    $route: {
      handler() {
        if (!this.$route.query.layer_id) {
          return
        }
        this.load()
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.unload()
  },
  methods: {
    async fetchDocument() {
      const { file: src } = this.annotateOn
      const { data: pdf } = await fetchDocument(src)
      return pdf
    },
    async load() {
      this.unload()
      try {
        const [pdf, layerAnnotations] = await Promise.all([
          this.fetchDocument(),
          this.annotateOn.fetchAnnotations(this.$route.query.layer_id),
        ])

        const pdfConfig = {
          container: this.$refs.pdfViewerContainer,
          baseUrl: `${document.location.origin}${this.$config.path_prefix}`,
          enableClipboardActions: true,
        }
        if (this.$config.pspdfkit_license_key) {
          pdfConfig.licenseKey = this.$config.pspdfkit_license_key
        }
        if (layerAnnotations.xfdf_string) {
          pdfConfig.XFDF = layerAnnotations.xfdf_string
          pdfConfig.toolbarItems = [] // hide annotation toolbar.
        } else {
          pdfConfig.instantJSON = {
            format: 'https://pspdfkit.com/instant-json/v1',
            annotations: layerAnnotations.annotations,
          }
        }

        const presetsToSave = ['ink', 'highlighter', 'text-highlighter', 'ink-eraser']
        let instance = null
        try {
          // Trying to load the viewer...
          pdfConfig.document = this.copyArrayBuffer(pdf)
          instance = await PSPDFKit.load(pdfConfig)
          instance.addEventListener('annotationPresets.update', (event) => {
            const key = event.currentPreset
            if (presetsToSave.includes(key)) {
              let preset = PSPDFKit.AnnotationPresets.toSerializableObject(event.newPresetProperties)
              const currentPreset = JSON.parse(localStorage.getItem(key))
              if (currentPreset) {
                preset = { ...currentPreset, ...preset }
              }
              localStorage.setItem(event.currentPreset, JSON.stringify(preset))
            }
          })
          setCustomToolbar(instance, this.annotateOn)
        } catch (e) {
          if (!(e instanceof PSPDFKit.Error) || !pdfConfig.instantJSON) throw e
          PSPDFKit.unload(this.$refs.pdfViewerContainer)
          // probably failed due to annotations of restored layers that have an invalid pageIndex.

          // We temporarily load the viewer once without annotations to determine the maxPageIndex.
          pdfConfig.instantJSON.annotations = []
          pdfConfig.document = this.copyArrayBuffer(pdf)
          instance = await PSPDFKit.load(pdfConfig)
          setCustomToolbar(instance, this.annotateOn)
          const maxPageIndex = instance.totalPageCount - 1
          PSPDFKit.unload(this.$refs.pdfViewerContainer)

          // Check which annotations are out of boundaries and need to be fixed (moved to first page)
          const [validAnnotations, invalidAnnotations] = partition(
            layerAnnotations.annotations,
            (annotation) => annotation.pageIndex <= maxPageIndex,
          )
          const fixedAnnotations = invalidAnnotations.map((a) => ({ ...a, pageIndex: 0 }))
          // Now load the viewer with the adjusted annotations.
          pdfConfig.instantJSON.annotations = [...validAnnotations, ...fixedAnnotations]
          pdfConfig.document = this.copyArrayBuffer(pdf)
          instance = await PSPDFKit.load(pdfConfig)
          setCustomToolbar(instance, this.annotateOn)

          // If that worked, save the annotations.
          await Promise.all(
            fixedAnnotations.map((fixedAnnotation) => {
              this.annotateOn.createOrUpdateAnnotation(this.$route.query.layer_id, fixedAnnotation)
            }),
          )
        }
        this.viewer = instance

        const myAnnotationPresets = instance.annotationPresets
        presetsToSave.forEach(function (key) {
          const preset = localStorage.getItem(key)
          if (preset) {
            myAnnotationPresets[key] = PSPDFKit.AnnotationPresets.fromSerializableObject(JSON.parse(preset))
          }
        })
        instance.setAnnotationPresets(myAnnotationPresets)
        // Hide image and stamp annotations because backend does not properly implement this feature
        instance.setToolbarItems((items) => {
          return items.filter((i) => !['image', 'stamp'].includes(i.type))
        })

        if (!layerAnnotations.xfdf_string) {
          instance.addEventListener('annotations.create', (createdAnnotations) => {
            const annotation = createdAnnotations.first()
            const serializedObject = PSPDFKit.Annotations.toSerializableObject(annotation)
            this.annotateOn.createOrUpdateAnnotation(this.$route.query.layer_id, serializedObject)
          })
          instance.addEventListener('annotations.update', (updatedAnnotations) => {
            const annotation = updatedAnnotations.first()
            const serializedObject = PSPDFKit.Annotations.toSerializableObject(annotation)
            this.annotateOn.createOrUpdateAnnotation(this.$route.query.layer_id, serializedObject)
          })
          instance.addEventListener('annotations.delete', (deletedAnnotations) => {
            const annotation = deletedAnnotations.first()
            this.annotateOn.deleteAnnotation(annotation.id)
          })
        }
      } catch (error) {
        console.error(error)
        PSPDFKit.unload(this.$refs.pdfViewerContainer)
      }
    },
    unload() {
      if (this.viewer) {
        PSPDFKit.unload(this.viewer)
        this.viewer = null
      }
    },
    copyArrayBuffer(orig) {
      const copy = new ArrayBuffer(orig.byteLength)
      new Uint8Array(copy).set(new Uint8Array(orig))
      return copy
    },
  },
}
</script>

<style></style>
